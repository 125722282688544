.datasheetText {
    padding-top: 30px;
    color: #ffffff;
    font-family: "Montserrat";
}

.datasheetLink {
    font-size: 18px;
    color: #ffffff;
    font-family: "Montserrat";
    font-weight: bold;
}

.bodytext {
    font-size: 20px;
    color: #ffffff;
    padding-top: 25px;
    font-family: "Montserrat";
}

.deviceBackground {
    height: 100%;
    background: linear-gradient(112deg, transparent 55%, #051b24 70%, transparent 70.05%),  url("../../../public/assets/images/Devicebg.png") no-repeat;
    background-color: #000000;
    background-size: 100%;
    padding-left: 0px;
}

.deviceImage {
    width: 350px;
    padding-top: 120px;
    padding-left: 60px;
}

.threeLogo {
    position: absolute;
    width: 20%;
    align-items: right;
    left: 79.5%;
    bottom: 120px;
    opacity: 0.7;
}

.bodyContainer {
    display: block;
    margin-left: 25%;
    margin-right: auto;
    width: 30%;
    padding-top: 30px;
}

.mainTitleDevicesMobile {
    padding-top: 110px;
    min-width: 200px;
    max-width: 450px;
    width: 60vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-family: "Montserrat";
}

.mobileSlashDevices {
    min-height: 100vh;
    background-image: url("../../../public/assets/images/deviceMobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000000;
}

.device-mobile-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40%;
    color: #ffffff;
    width: 50%;
    font-size: 14px;
    text-align: left;
    font-family: "Montserrat";
}

.datasheetTextMobile {
    padding-top: 30px;
    color: #ffffff;
}

@media screen and (max-width: 1200px) {
    .bodytext {
        font-size: 14px;
        color: #ffffff;
        padding-top: 7%;
    }

    .datasheetLink {
        font-size: 12px;
    }

    .deviceImage {
        width: 300px;
        padding-top: 200px;
    }

    .bodyContainer {
        display: block;
        margin-left: 37%;
        margin-right: auto;
    }
}

@media screen and (min-width: 2000px) {
    .bodytext {
        font-size: 25px;
    }

    .datasheetLink {
        font-size: 23px;
    }
}