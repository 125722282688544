.footer {
    text-align: center;
    color: aliceblue;
    position: relative;
    bottom: 75px;
    padding-top: 30px;
    margin: auto;
    font-size: 15px;
}

.sideLayout {
    min-height: 100vh;
}

.logo {
    margin-left: 38%;
    margin-top: 75px;
    margin-bottom: 30px;
    width: 24%;
}

.topLogin {
    position: absolute;
    right: 0;
}

.loginButton {
    color: rgb(255, 255, 255);
    margin-right: 40px;
    margin-top: 20px;
    font-size: large;
}

.contentBackground {
    background-color: #060606;
}

.ant-menu-submenu-selected {
    background-color: #1677ff;
}

.ant-layout .ant-layout-sider {
    background-color: #000000;
}

.ant-menu-dark {
    background-color: #000000;
}

.ant-menu-dark.ant-menu-submenu>.ant-menu {
    background-color: #000000;
}

.ant-layout.ant-layout-has-sider {
    height: 100%;
    background-color: #000000;
}

.ant-layout .ant-layout-content {
    height: 100%;
}

.ant-btn-text:not(:disabled):hover {
    color: #cccccc;
}

.ant-btn {
    padding-left: 0px;
}

.ant-drawer-body {
    background-color: #000000;
}

.ant-drawer-header {
    background-color: #000000;
}

.closeButton {
    color: #ffffff;
}

.anticon-close {
    color: #ffffff;
    height: 30px;
    width: 30px;
}

.hamburger {
    position: absolute;
    height: 60px;
    width: 60px;
}

.footerMobile {
    display: block;
    margin-left: 25%;
    width: 50%;
    margin-right: auto;
    text-align: center;
    position: relative;
    color: #ffffff;
    background-color: #000000;
    padding-bottom: 30px;
    padding-top: 30px;
    bottom: 0;
}

.loginMobile {
    position: absolute;
    right: 0;
}

.mobileLoginButton {
    color: #ffffff;
    margin-right: 16px;
    margin-top: 20px;
    font-size: 16px;
}

@media screen and (max-width: 901px) {
    .logo {
        margin-top: 0px;
    }
}