.maintext {
    color: aliceblue;
    font-size: 40px;
    position: relative;
    text-align: center;
    width: 100%;
    padding-top: 100px;
}

.mobileSlashError {
    min-height: 100vh;
    background-image: url("../../../public/assets/images/errorMobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000000;
}