.headerimage {
    width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    min-width: 200px;
}

.aboutusBody {
    color: #ffffff;
    display: block;
    margin-left: 20%;
    margin-right: auto;
    width: 50%;
    font-size: 20px;
    padding-top: 50px;
    font-family: "Montserrat";
    position: absolute;
}

.aboutusBodyMobile {
    color: #ffffff;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    font-size: 20px;
    padding-top: 40%;
    font-family: "Montserrat";
}


.backgroundAboutStripe{
    min-height: 100vh;
    height: 100%;
    background-image: url("../../../public/assets/images/aboutMobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000000;
}

@media screen and (max-width: 1200px) {
    .aboutusBody {
        font-size: 14px;
    }
}

@media screen and (min-width: 2000px) {
    .aboutusBody {
        font-size: 25px;
    }
}