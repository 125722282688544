.mondayForm {
    border: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 85%;
}

.headercontactimage {
    width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    min-width: 200px;
}

.backgroundStripe {
    background: linear-gradient(112deg, #000000 55%, #051b24 70%, #000000 70.05%);
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
}

div.form-wrapper-empty-header {
    height: 0;
}

.backgroundStripeContact{
    min-height: 100vh;
    height: 100%;
    background-image: url("../../../public/assets/images/contactMobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000000;
}

.mondayFormMobile {
    padding-top: 25%;
    border: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 85vh;
}