.backgroundSlash {
    background: linear-gradient(112deg, #ffffff 46vw, #000000 46.05vw);
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
}

.mainTitle {
    padding-top: 70px;
    width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.flexcontainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2%;
}

.laptop {
    flex: 64%;
    min-width: 600px;
    position: relative;
}

.mainText {
    position: relative;
    margin-top: 5%;
    flex: 36%;
    width: 68%;
    color: #ffffff;
    padding-right: 150px;
    margin-left: auto;
    align-content: right;
    font-size: 20px;
    font-family: "Montserrat";
}

.titleText {
    font-size: 40px;
}

.laptopFrame {
    position: relative;
    width: 100%;
}

.laptopButtons {
    position: absolute;
    top: 15%;
    right: 11%;
    width: 2%;
    display: block;
    flex-direction: column;
    z-index: 2;
}

.buttonStyle {
    margin-bottom: 1em;
}

.ant-btn.ant-btn-icon-only {
    width: 80px;
    height: 50px;
    border: none;
    background: linear-gradient(90deg, #000000 0%, #7c7c7c 100%);
}

.buttonImage {
    margin-top: 0px;
    padding-left: 25px;
    font-size: 30px;
    color: #aaaaaa;
}

.buttonSelected {
    margin-top: 0px;
    padding-left: 25px;
    font-size: 30px;
    color: #ffffff;
}

.preRender {
    display: none;
}

.mainTitleMobile {
    padding-top: 110px;
    min-width: 300px;
    max-width: 523px;
    width: 60vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.laptopFrameMobile {
    padding-top: 20%;
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.laptopButtonsMobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.mainTextMobile {
    color: #ffffff;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 0px;
    font-family: "Montserrat";
}

.mobileText {
    margin-bottom: 0px;
}

.mobileSlashHome{
    min-height: 100vh;
    background-image: url("../../../public/assets/images/homeMobile.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000000;
}

@media screen and (max-width: 1310px) {
    .laptop {
        width: 100%;
        padding-top: 20px;
        max-width: 700px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .mainText {
        text-align: right;
        width: 50vw;
    }
    .flexcontainer {
        flex-direction: column;
    }
}

@media screen and (min-width: 2000px) {
    .mainText {
        font-size: 25px;
    }
    .titleText {
        font-size: 50px;
    }
}

@media screen and (max-width: 901px) {
    .ant-btn.ant-btn-icon-only {
        width: 50px;
        height: 40px;
        background: linear-gradient(180deg, #000000 0%, #7c7c7c 100%);
        margin-left: 25px;
        margin-top: -20px;
    }
    .buttonSelected{
        padding-left: 1.5px;
        margin-top: 0px;
    }
    .buttonImage {
        padding-left: 1.5px;
        margin-top: 0px;
    }
}